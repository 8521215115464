import request from "@/utils/request";

// RoleOption
export function roleOption(params) {
  return request({ url: `/roles/options/`, method: "get", params });
}

// UserOption
export function userOption(params) {
  return request({ url: `/users/options/`, method: "get", params });
}

// Warehouse
export function warehouseOption(params) {
  return request({ url: `/warehouses/options/`, method: "get", params });
}

// 货位
export function shelveOption(params) {
  return request({ url: `/shelve_records/options/`, method: "get", params });
}

// 子批次
export function subBatchOption(params) {
  return request({ url: `/sub_batchs/options/`, method: "get", params });
}

// 异动产品
export function changeMaterialOption(params) {
  return request({ url: `/sub_batchs/options/change_materials/`, method: "get", params });
}

// 库存
export function inventoryOption(params) {
  return request({ url: `/inventories/options/`, method: "get", params });
}

// ReservoirArea
export function reservoirAreaOption(params) {
  return request({ url: `/reservoir_areas/options/`, method: "get", params });
}

// Location
export function locationOption(params) {
  return request({ url: `/locations/options/`, method: "get", params });
}

// Location
export function locationRecommendOption(params) {
  return request({ url: `/location_recommend/`, method: "get", params });
}

// Client
export function clientOption(params) {
  return request({ url: `/clients/options/`, method: "get", params });
}

// Unit
export function unitOption(params) {
  return request({ url: `/units/options/`, method: "get", params });
}

// OrderType
export function orderTypeOption(params) {
  return request({ url: `/order_types/options/`, method: "get", params });
}

// Category
export function categoryOption(params) {
  return request({ url: `/categories/options/`, method: "get", params });
}

// Material
export function materialOption(params) {
  return request({ url: `/materials/options/`, method: "get", params });
}

// 拣货记录创建-拣货产品
export function pickingRecordCreateMaterials(params) {
  return request({ url: `/picking_materials/`, method: "get", params });
}

// WarehouseInventoryOption
export function warehouseInventoryOption(params) {
  return request({ url: `/warehouse_inventories/options/`, method: "get", params });
}

// Batch
export function batchOption(params) {
  return request({ url: `/batchs/options/`, method: "get", params });
}

// ReceiptOrder
export function receiptOrderOption(params) {
  return request({ url: `/receipt_orders/options/`, method: "get", params });
}

// ReceiptMaterial
export function receiptMaterialOption(params) {
  return request({ url: `/receipt_materials/options/`, method: "get", params });
}

// ReceiptRecord
export function receiptRecordOption(params) {
  return request({ url: `/receipt_records/options/`, method: "get", params });
}

// ReceiptRecordMaterial
export function receiptRecordMaterialOption(params) {
  return request({ url: `/receipt_record_materials/options/`, method: "get", params });
}

// ShelveRecord
export function shelveRecordOption(params) {
  return request({ url: `/shelve_records/options/`, method: "get", params });
}

// DeliveryOrder
export function deliveryOrderOption(params) {
  return request({ url: `/delivery_orders/options/`, method: "get", params });
}

// 复核产品
export function deliveryMaterialOption(params) {
  return request({ url: `/delivery_materials/options/`, method: "get", params });
}

// StockCheckOrder
export function stockCheckOrderOption(params) {
  return request({ url: `/stock_check_orders/options/`, method: "get", params });
}

// StockCheckMaterial
export function stockCheckMaterialOption(params) {
  return request({ url: `/stock_check_materials/options/`, method: "get", params });
}

// stockOutOrder
export function stockOutOrderOption(params) {
  return request({ url: `/delivery_orders/options/`, method: "get", params });
}

// 收费项目
export function chargeItemsOption(params) {
  return request({ url: `/charge_items/options/`, method: "get", params });
}

// 入库通知单
export function receiptOrdersOption(params) {
  return request({ url: `/receipt_orders/options/`, method: "get", params });
}

// 出库通知单
export function deliveryOrdersOption(params) {
  return request({ url: `/delivery_orders/options/`, method: "get", params });
}

export function deliveryClientOption(params) {
  return request({ url: `/delivery_clients/options/`, method: "get", params });
}
